//export const apihost = 'https://apimsa.mysportapp.ch';
//export const apihost = 'https://apidynamic.mysportapp.ch';
//export const apihost = 'https://apitgym.mysportapp.ch';
//export const apihost = 'https://apix30.mysportapp.ch';
export const apihost = 'https://apiprofitfitness.mysportapp.ch';
//export const apihost = 'https://apiphysioplus.mysportapp.ch';
//export const apihost = 'https://apigoldsgym.mysportapp.ch';
//export const apihost = 'https://apifitx.mysportapp.ch';
//export const apihost = 'https://apikuoni.mysportapp.ch';
//export const apihost = 'https://apichilihealth.mysportapp.ch';
//export const apihost = 'https://apifitnessunited.mysportapp.ch';
//export const apihost = 'https://apineogate.mysportapp.ch';
//export const apihost = 'https://apichristoppark.mysportapp.ch';
//export const apihost = 'https://apibesttraining.mysportapp.ch';
//export const apihost = 'https://apiquantumgym.mysportapp.ch';
//export const apihost = 'https://apigym365.mysportapp.ch';
//export const apihost = 'https://apitrainiq.mysportapp.ch';
//export const apihost = 'https://apilfit.mysportapp.ch';

//export const apihost = 'https://pimcore11msa.kohler-digital.ch';

export const pdfhost = 'https://wkhtmltopdf.kohler-digital.ch';

export const version = '01b.23';
